<template>
  <v-container>
    <v-subheader>
      <v-btn
        icon
        small
        to="/"
      >
        <v-icon small>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <strong class="ml-2">{{ $t('post_fb_tt') }}</strong>
    </v-subheader>

    <div class="text-center">
      <v-tabs
        centered
        color="blue"
        background-color="nav_bg"
      >
        <v-tab>{{ $t('huodong') }}</v-tab>
        <v-tab>{{ $t('kaijiangjieguo') }}</v-tab>

        <v-tab-item class="app_bg">
          <v-card
            color="box_bg"
          >
            <v-img
              src="../assets/img/post_fb_bg.webp"
              class="d-flex align-center rounded-t-0"
            >
              <div
                class="pa-5 white--text"
              >
                <div class="d-flex align-center justify-center mb-3">
                  <v-icon
                    large
                    dark
                  >
                    mdi-facebook
                  </v-icon>
                  <v-icon
                    small
                    dark
                    class="mx-2"
                  >
                    mdi-close
                  </v-icon>
                  <img
                    width="28"
                    src="../assets/logo.svg"
                  >
                </div>
                <h3>{{ $t('post_fb_tt2') }}</h3>
                <div class="my-3 text-body-2">
                  {{ $t('canyurenshu') }}
                  <strong class="yellow--text">{{ total }}</strong>
                  {{ $t('ren') }}
                </div>
                <div class="my-3 text-body-2">
                  {{ $t('huodongshijian') }}
                </div>
              </div>
            </v-img>
            <v-card-text style="background-color: #0935B3;">
              <v-chip
                color="light-blue lighten-2"
                class="d-block ma-1"
              >
                <v-icon left>
                  mdi-trophy
                </v-icon>
                {{ $t('post_fb_bonus1') }}

                <v-icon
                  small
                  class="ml-1"
                >
                  mdi-plus
                </v-icon>

                <v-img
                  sizes="24"
                  width="24"
                  src="../assets/img/phone-sm.webp"
                />
                Galaxy Z Fold5
              </v-chip>
              <v-chip
                color="light-blue lighten-1"
                class="d-block ma-1"
              >
                <v-icon left>
                  mdi-trophy-variant
                </v-icon>
                {{ $t('post_fb_bonus2') }}

                <v-icon
                  small
                  class="ml-1"
                >
                  mdi-plus
                </v-icon>

                <v-img
                  sizes="24"
                  width="24"
                  src="../assets/img/phone-iphone15.webp"
                />
                iPhone 15 Pro
              </v-chip>
              <v-chip
                color="light-blue"
                class="d-block ma-1"
              >
                <v-icon left>
                  mdi-trophy-award
                </v-icon>
                {{ $t('post_fb_bonus3') }}
              </v-chip>
              <v-chip
                color="light-blue darken-1"
                class="d-block ma-1"
              >
                <v-icon left>
                  mdi-trophy-award
                </v-icon>
                {{ $t('post_fb_bonus4') }}
              </v-chip>
              <v-chip
                color="light-blue darken-2"
                class="d-block ma-1"
              >
                <v-icon left>
                  mdi-trophy-award
                </v-icon>
                {{ $t('post_fb_bonus5') }}
              </v-chip>
              <v-chip
                color="light-blue darken-3"
                class="d-block ma-1"
              >
                <v-icon left>
                  mdi-trophy-award
                </v-icon>
                {{ $t('post_fb_bonus6') }}
              </v-chip>
            </v-card-text>
          </v-card>

          <v-card
            color="box_bg"
            class="mt-3"
          >
            <v-card-text
              v-if="participated<1"
              class="text-center py-3"
            >
              <div
                v-if="f1!=null"
                class="nav_bg pa-3 rounded mx-auto"
              >
                <div class="mb-2">
                  <v-btn
                    depressed
                    fab
                    x-small
                    dark
                    color="red accent-2"
                    @click="delimg"
                  >
                    <v-icon color="red1">
                      mdi-delete-forever
                    </v-icon>
                  </v-btn>
                </div>
                  
                <img
                  v-if="f1==null"
                  width="100"
                  src="../assets/img/pic/postimg-01.jpg"
                >
                <img
                  v-if="f1!=null"
                  width="100"
                  :src="f1"
                >
              </div>
                
              <div
                v-if="f1==null"
                class="d-flex justify-center"
              >
                <v-avatar
                  color="nav_bg"
                  size="56"
                >
                  <v-file-input
                    ref="ff1"
                    v-model="file1"
                    hide-input
                    accept="image/png, image/jpeg, image/jpg"
                    prepend-icon="mdi-camera-plus"
                    class="pa-0 ma-0 ml-2"
                    style="flex: none!important;"
                    @change="onSelectFile()"
                  />
                </v-avatar>
              </div>

              <div class="mt-3">
                <v-text-field
                  v-model="link"
                  flat
                  dense
                  filled
                  rounded
                  label="Post Link"
                  :hide-details="link_error?false:true"
                  :error-messages="link_error"
                  clearable
                  background-color="nav_bg"
                  prepend-inner-icon="mdi-link-variant"
                  placeholder="Post Link..."
                  class="rounded"
                />
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn
                depressed
                block
                color="primary"
                class="text-center"
                :loading="loading"
                :disabled="participated>0"
                @dblclick="dblclick"
                @click="savefblottery"
              >
                {{ $t('tijiao') }}
              </v-btn>
            </v-card-actions>

            <v-divider class="opacity-3 my-2" />
            <v-card-title class="py-3 text-body-1">
              {{ $t('guize') }}
            </v-card-title>

            <v-card-text>
              1.{{ $t('post_fb_rule1') }}
              <div class="text-center my-2">
                <v-btn
                  outlined
                  dark
                  rounded
                  color="blue"
                  href="https://www.facebook.com/share/p/C4eGR5SBP1vtZYrR/?mibextid=K8Wfd2"
                  target="_blank"
                >
                  <v-icon left>
                    mdi-facebook
                  </v-icon>
                  {{ $t('post_fb_btn') }}
                </v-btn>
                <br>
              </div>
              2.{{ $t('post_fb_rule2') }}
              <br>
              3.{{ $t('post_fb_rule3') }}
              <br>
              4.{{ $t('post_fb_rule4') }}

              <v-subheader class="px-0 subtitle-2">
                {{ $t('jlck') }}
              </v-subheader>
              <v-carousel
                cycle
                height="400"
              >
                <v-carousel-item
                  v-for="p in 2"
                  :key="'i'+p"
                  :src="require('@/assets/img/pic/fb-img-0'+p+'.jpg')"
                />
              </v-carousel>
            </v-card-text>

            <v-divider class="opacity-3 my-2" />
            <v-card-title
              v-if="list.length>0"
              class="py-3 text-body-1"
            >
              {{ $t('lsjl') }}
            </v-card-title>

            <v-list
              color="box_bg"
              class="text-caption pt-0"
            >
              <div
                v-for="(item, index) in list"
                :key="'h'+index"
              >
                <v-divider class="opacity-3" />
                <v-list-item>
                  <v-list-item-action>
                    {{ item.created }}
                  </v-list-item-action>
                  <v-list-item-content>
                    <div>
                      <img
                        width="50"
                        class="mx-1"
                        :src="item.imgs"
                      >
                    </div>
                  </v-list-item-content>
                  <v-list-item-action
                    v-if="item.status==0"
                    class="text-right"
                  >
                    {{ $t('daikaijiang') }}
                  </v-list-item-action>
                  <v-list-item-action
                    v-if="item.status==1"
                    class="text-right"
                  >
                    <div>
                      <div class="orange--text font-weight-medium">
                        + R$ {{ item.money }}
                      </div>
                      <span class="green--text">{{ $t('zhongjiang') }}</span>
                    </div>
                  </v-list-item-action>
                  <v-list-item-action
                    v-if="item.status==-1"
                    class="text-right"
                  >
                    <div class="text-center">
                      <div class="red--text">
                        {{ $t('weizhongjiang') }}
                      </div>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card
            color="box_bg"
          >
            <v-img
              height="100"
              src="../assets/img/post_fb_bg2.webp"
              class="d-flex align-center rounded-t-0"
            >
              <h2 class="text-center green--text">
                {{ $t('kaijiangjieguo') }}
              </h2>
            </v-img>
            <v-list
              dense
              color="box_bg"
            >
              <v-list-item>
                <v-list-item-action>
                  <small>Ranking</small>
                </v-list-item-action>
                <v-list-item-content class="text-left pl-2">
                  <small>Winner</small>
                </v-list-item-content>
                <v-list-item-action>
                  <small>Prize</small>
                </v-list-item-action>
              </v-list-item>

              <div
                v-for="(item, index) in results"
                :key="'r'+index"
              >
                <v-divider class="opacity-3" />
                <v-list-item>
                  <v-list-item-action>
                    <v-chip
                      small
                      dark
                      color="blue"
                    >
                      {{ index+1 }}
                    </v-chip>
                  </v-list-item-action>
                  <v-list-item-avatar
                    size="24"
                  >
                    <v-img :src="require('../assets/img/avatar/'+item.avatar+'.png')" />
                  </v-list-item-avatar>
                  <v-list-item-content class="text-left pl-2">
                    <v-list-item-subtitle>{{ item.area_code }} {{ item.phone }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <strong class="orange--text">
                      $ {{ item.money }}
                    </strong>
                    <template v-if="index==0">
                      <div class="d-flex align-center text-body-2 indigo white--text px-2 py-1 rounded-lg">
                        <v-icon
                          small
                          dark
                          class="opacity-8"
                        >
                          mdi-plus
                        </v-icon>

                        <v-img
                          sizes="24"
                          width="24"
                          src="../assets/img/phone-sm.webp"
                        />
                        <small>Galaxy Z Fold5</small>
                      </div>
                    </template>
                    <template v-if="index==1">
                      <div class="d-flex align-center text-body-2 indigo white--text px-2 py-1 rounded-lg">
                        <v-icon
                          small
                          dark
                          class="opacity-8"
                        >
                          mdi-plus
                        </v-icon>

                        <v-img
                          sizes="24"
                          width="24"
                          src="../assets/img/phone-iphone15.webp"
                        />
                        <small class="ml-1">iPhone 15 Pro</small>
                      </div>
                    </template>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
    <template v-if="showdialog">
      <Login
        v-model="loginreg"
        @closeloginreg="closeloginreg"
        @showforget="showforget"
      />
    </template>
    <template v-if="forget">
      <Forget
        @closeforget="closeforget"
      />
    </template>
  </v-container>
</template>

<script>
import Login from '@/components/Login.vue'
import Forget from '@/components/Forget.vue'

export default {
  components: {
    Login,
    Forget
  },
  data: () => ({
    list: [],
    total: 0,
    results: [],
    participated: 0,
    showdialog: false,
    loginreg: false,
    forget: false,
    file1: null,
    f1: null,
    link: '',
    link_error: '',
    loading: false,
  }),
  computed: {

  },
  watch: {

  },
  created() {

  },
  mounted() {
    this.fblotteryrecords()
    this.fblotteryresults()
  },
  beforeDestroy() {

  },
  methods: {
    fblotteryrecords() {
      if(!this.getStorage('member_id')){
        return false
      }
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.fblotteryrecords(paramObj).then((response) => {
        if(response.code==200){
          this.list = response.data.list
          this.total = response.data.total
          this.participated = response.data.participated
          //this.hourcountdown()
        }
      })
    },
    fblotteryresults() {
      let paramObj = {
        member_id: 0
      }
      this.$server.fblotteryresults(paramObj).then((response) => {
        if(response.code==200){
          this.results = response.data.list
        }
      })
    },
    hourcountdown() {
      this.hourtimer = setInterval(() => {
        if (this.countdownhour > 0) {
          this.countdownhour--
          let hours = Math.floor(this.countdownhour / 3600)
          let minutes = Math.floor(this.countdownhour / 60) % 60
          let seconds = this.countdownhour % 60
          this.daojishi = hours+':'+minutes+':'+seconds
        }else{
          this.daojishi = ''
          clearInterval(this.hourtimer)
          this.hourtimer = null
        }
      }, 1000)
    },
    showloginreg(tab) {
      this.loginreg = tab
      this.showdialog = true
    },
    closeloginreg() {
      this.loginreg = false
      this.showdialog = false
    },
    showforget() {
      this.forget = true
    },
    closeforget() {
      this.forget = false
    },
    dblclick() {
      console.log('dblclick')
    },
    delimg() {
      this.file1 = null
      this.f1 = null
    },
    onSelectFile() {
      if(!this.getStorage('member_id')){
        this.showloginreg('login')
        return false
      }
      if(this.file1.size>20000000){
        this.file1 = null
        this.$snackbar.error('O tamanho da imagem deve ser inferior a 20 MB')
        return false
      }
      let paramObj = {
        member_id: this.getStorage('member_id'),
        file: this.file1
      }
      this.$server.uploadfblottery(paramObj).then((response) => {
        if(response.code==200){
          this.f1 = response.data.url
        }else{
          this.file1 = null
          this.f1 = null
          this.$snackbar.error(response.msg)
        }
      })
    },
    savefblottery() {
      this.loading = true
      if(this.f1==null){
        this.$snackbar.error(this.$t('zs1zt'))
        this.loading = false
        return false
      }
      if(!this.link){
        this.link_error = this.$t('tgljbnwk')
        this.loading = false
        return false
      }
      if(!this.link.includes('facebook')){
        this.link_error = this.$t('ljgscw')
        this.loading = false
        return false
      }
      this.link_error = ''
      let paramObj = {
        member_id: this.getStorage('member_id'),
        imgurl: this.f1,
        link: this.link,
      }
      this.$server.savefblottery(paramObj).then((response) => {
        if(response.code==200){
          this.file1 = null
          this.f1 = null
          this.link = ''
          this.link_error = ''
          this.fblotteryrecords()
        }else{
          this.$snackbar.error(response.msg)
        }
        this.loading = false
      })
    },
  }

}
</script>